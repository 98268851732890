import PubSub from 'vanilla-pubsub'

export default class {
  constructor() {
    this.menu = null
    this.trigger = null
    this.isExpanded = false
    this.state = {
      bannerHeight: 0,
      headerHeight: 0,
    }

    this.initialize()
  }

  initialize() {
    this.menu = document.querySelector('.js-menu')
    this.trigger = document.querySelector('.js-menuTrigger')
    this.banner = document.querySelector('.c-billboardBanner')
    this.header = document.querySelector('.l-header__container')
    this.state.bannerHeight = this.banner?.clientHeight || 0
    this.state.headerHeight = this.header?.clientHeight || 0

    this.bind()
  }

  bind() {
    this.trigger.addEventListener('click', this.handleClick.bind(this))
    PubSub.subscribe('App.changeViewport', ({ isMobile }) => {
      if (!isMobile && this.isExpanded) {
        this.close()
      }
    })
  }

  handleClick() {
    if (this.isExpanded) {
      this.close()
    } else {
      this.expand()
    }
  }

  expand() {
    this.menu.setAttribute('aria-hidden', false)
    this.trigger.setAttribute('aria-expanded', true)
    document.documentElement.style.overflow = 'hidden'

    if (window.scrollY < this.state.bannerHeight) {
      this.menu.style.height =
        window.innerHeight -
        (this.state.bannerHeight + this.state.headerHeight) +
        'px'
    } else {
      this.menu.style.height =
        window.innerHeight - this.state.headerHeight + 'px'
    }

    this.isExpanded = true
  }

  close() {
    this.menu.setAttribute('aria-hidden', true)
    this.trigger.setAttribute('aria-expanded', false)
    document.documentElement.style.overflow = ''
    this.menu.style.height = ''
    this.isExpanded = false
  }
}

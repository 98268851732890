import PubSub from 'vanilla-pubsub'
import React, { useState, useRef } from 'react'
import { DayPicker } from 'react-dates'
import dayjs from '../../plugins/dayjs'
import Overlay, { defaultStyle } from '../../libs/Overlay'
import { useDidMount } from '../utils'

import 'moment/locale/ja'
import 'react-dates/initialize'

interface IProps {
  navPrevDisabled?: boolean
  navNextDisabled?: boolean
}

// docs -> https://www.notion.so/D1_what-s-happening-e23230476c544e00b12226be60bbf2f6

const mockEventData = [{}]

const DatePicker: React.FC<IProps> = (props) => {
  const [date, setDate] = useState(null)
  const [focused, setFocused] = useState(null)
  const [isVisible, setVisible] = useState(false)
  const [isNavPrevDisabled, setNavPrevDisabled] = useState(
    props.navPrevDisabled ?? true
  )
  const [isNavNextDisabled, setNavNextDisabled] = useState(
    props.navNextDisabled ?? true
  )
  const overlay = new Overlay({
    targetSelector: '.js-datePicker',
    overlayStyle: {
      ...defaultStyle,
      'z-index': '0',
      transition: 'opacity 0.4s',
    },
    externalElements: document.querySelectorAll('.l-header'),
  })
  const [overlayInstance, setOverlayInstance] = useState(overlay)

  const handleToggle = (isVisible) => {
    if (overlayInstance.isVisible) {
      overlayInstance.hide()
    } else {
      overlayInstance.visible()
    }

    setVisible(!isVisible)
  }

  const onPrevMonthClick = () => {
    console.log('onPrevMonthClick')
  }

  const onNextMonthClick = () => {
    console.log('onNextMonthClick')
  }

  // マウント後に、オーバーレイを作成
  useDidMount(() => {
    overlayInstance.createOverlay()
    PubSub.subscribe('Overlay.click', () => {
      handleToggle(overlayInstance.isVisible)
    })
  })

  // TODO: mountアニメーション
  // 検討ライブラリ
  // react-transition-group
  // react-animated-css

  return (
    <div className={`p-datePicker${isVisible ? ' -isPickerVisible' : ''}`}>
      <p className="p-datePicker__heading">今月のカレンダーをみる</p>
      <div className="p-datePicker__content">
        <button
          type="button"
          className="p-datePicker__trigger"
          onClick={() => handleToggle(isVisible)}
        >
          {isVisible ? <i className="iconfont-close"></i> : <i className="iconfont-calendar"></i>}
        </button>
        <div
          className="p-datePicker__picker"
          aria-hidden={isVisible ? 'false' : 'true'}
        >
          <DayPicker
            navPrev={isNavPrevDisabled ? <div aria-hidden="true" /> : ''}
            navNext={isNavNextDisabled ? <div aria-hidden="true" /> : ''}
            onPrevMonthClick={onPrevMonthClick}
            onNextMonthClick={onNextMonthClick}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
              <strong>{`${dayjs().year()}.${dayjs().format('MM')}`}</strong>
            )}
            renderDayContents={(day) =>
              dayjs(day.toDate()).isToday() ? (
                <span className="CalendarDay__today">{day.format('D')}</span>
              ) : (
                day.format('D')
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

export default DatePicker

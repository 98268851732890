import PubSub from 'vanilla-pubsub'
import project from '../project'
import { wait } from '../utils'

export default class {
  constructor(selector) {
    this.trigger = document.querySelector(selector)
    this.search = document.querySelector('.js-searchTarget')
    this.isExpanded = false

    if (this.trigger && this.search) {
      this.initialize()
    }
  }

  initialize() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    if (project.$window.isMobile) {
      this.search.setAttribute('aria-hidden', true)
    }
    this.bind()
  }

  bind() {
    this.trigger.addEventListener('click', this.handleClick.bind(this))
    PubSub.subscribe('App.changeViewport', ({ isMobile }) => {
      if (!isMobile && this.isExpanded) {
        this.close()
      }
    })
  }

  handleClick() {
    if (this.isExpanded) {
      this.close()
    } else {
      try {
        if (project.modules.menu.isExpanded) {
          project.modules.menu.close()
        }
      } catch (err) {
        console.error(err)
      }
      this.expand()
    }
  }

  handleClickOutside(event) {
    const isClickSearchInside = this.search.contains(event.target)
    const isClickTriggerInside = this.trigger.contains(event.target)

    if (!isClickSearchInside && !isClickTriggerInside) {
      this.close()
    }
  }

  async expand() {
    this.search.setAttribute('aria-hidden', false)
    this.trigger.setAttribute('aria-expanded', true)
    document.body.classList.add('is-search')
    document.addEventListener('click', this.handleClickOutside)
    await wait(20)
    document.body.classList.add('is-search-open')
    this.isExpanded = true
  }

  async close() {
    this.search.setAttribute('aria-hidden', true)
    this.trigger.setAttribute('aria-expanded', false)
    document.body.classList.remove('is-search-open')
    document.removeEventListener('click', this.handleClickOutside)
    await wait(300) // TODO: overlay の transitionDuration を取った方が良い
    document.body.classList.remove('is-search')
    this.isExpanded = false
  }
}

import React, { useState, useEffect, useRef } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import dayjs from 'dayjs'
import API from '@cinra/rest-api-client'

const $cinra = new API.CinraService()

interface ICommentProps {
  articleId: string
  userId: string
  userName: string
  userIcon: string
}

interface ICommentFormProps {
  icon: string
  post: any
  commented: boolean
  processing: boolean
}

interface ICommentItemProps {
  data: {
    id: string
    created_at: string
    body: string
    user: {
      id: string
      icon: string
      name: string
    }
  }
  userId: string
  handleRemove
}

// コメントアイテム
const CommentItem: React.FC<ICommentItemProps> = ({
  data,
  userId,
  handleRemove,
}) => {
  const element = useRef(null)
  const createdAt = useRef('')
  const userIcon = useRef(
    data.user.icon || '/assets/img/common/default_user.jpg'
  )

  const handleClick = () => {
    handleRemove(data.id)
  }

  createdAt.current = data.created_at

  return (
    <div ref={element} className="p-commentItem">
      <div className="p-commentItem__inner">
        <img
          src={userIcon.current}
          alt=""
          className="p-commentItem__avator"
          decoding="async"
        />
        <div className="p-commentItem__content">
          <div className="p-commentItem__header">
            <div className="p-commentItem__meta">
              <em className="p-commentItem__userName">{data.user.name}</em>
              <span className="p-commentItem__createdAt">
                ・{createdAt.current}
              </span>
            </div>
            {String(data.user.id) === userId && (
              <div className="p-commentItem__action">
                <button
                  type="button"
                  className="p-commentItem__delete iconfont-trash"
                  aria-label="コメントを削除する"
                  onClick={handleClick}
                ></button>
              </div>
            )}
          </div>
          <p className="p-commentItem__comment">{data.body}</p>
        </div>
      </div>
    </div>
  )
}

// 削除されたコメント
const CommentBannedItem = () => {
  return (
    <div className="p-commentItem">
      <div className="p-commentItem__inner">
        <div className="p-commentItem__avator"></div>
        <div className="p-commentItem__content">
          <p className="p-commentItem__banned">
            このコメントは規約により削除しました
          </p>
        </div>
      </div>
    </div>
  )
}

// コメントフォーム
const CommentForm: React.FC<ICommentFormProps> = ({
  icon,
  post,
  commented,
  processing,
}) => {
  console.log(icon)
  const MAX_LENGTH = 500
  const [comment, setComment] = useState('')
  const [count, setCount] = useState(0)
  const userIcon = useRef(icon || '/assets/img/common/default_user.jpg')

  const handleInput = (e) => {
    const value = (e.target as HTMLTextAreaElement).value
    setComment(value)
    setCount(value.length)
  }

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    post(comment)
    setCount(0)
  }

  useEffect(() => {
    if (commented) {
      setComment('')
    }
    return () => {}
  }, [commented])

  return (
    <form
      className="p-commentForm"
      onSubmit={submit}
      aria-disabled={commented}
    >
      <img
        src={userIcon.current}
        alt=""
        className="p-commentForm__avator"
        decoding="async"
      />
      <div className="p-commentForm__content">
        <div className="p-commentForm__header">
          <p>コメントを書く</p>
          <span className="p-commentForm__remainings">
            {count}/{MAX_LENGTH}
          </span>
        </div>
        <textarea
          value={comment}
          className="p-commentForm__textarea c-textarea"
          placeholder="コメントを入力する…"
          maxLength={MAX_LENGTH}
          onInput={handleInput}
        ></textarea>
        <button
          type="submit"
          className="p-commentForm__button c-button -outline -small"
          disabled={!comment || processing}
        >
          <span className="c-button__inner">
            <span className="c-button__label">投稿する</span>
          </span>
        </button>
      </div>
    </form>
  )
}

// const getPostDate = (created_at:string) :string => {
//   const current = dayjs()
//   const created = dayjs(created_at)
//   const diff = {
//     day: current.diff(created, 'day'),
//     minute: current.diff(created, 'minute')
//   }

//   if (diff.minute <= 10) {
//     // 10分以内
//     return 'Just Now'
//   } else if (diff.day < 1) {
//     // 当日
//     return 'Today'
//   } else if (diff.day < 2) {
//     // 前日
//     return 'Yesterday'
//   } else {
//     // 前日以前
//     return `${diff.day} days ago`
//   }
// }

// コメント全体
const Comment: React.FC<ICommentProps> = (props) => {
  const [data, setData] = useState([])

  // コメント済かどうか
  const [commented, setCommented] = useState(false)

  // 投稿のカウント
  const [count, setCount] = useState(0)

  // ページ数
  const [page, setPage] = useState(1)

  // 確認モーダル表示フラグ
  const [confirmation, confirm] = useState(false)

  // 全件取得、スピナーを非表示
  const [isDone, done] = useState(false)

  // API通信中か
  const [processing, process] = useState(false)

  // 削除ボタンがクリックされたコメントのid
  const commentId = useRef(null)

  // 記事の総件数
  const total = useRef(0)

  // CommentItemの削除ボタンクリックで呼ばれる
  // idをセットして確認モーダルを表示
  const handleRemove = (id) => {
    commentId.current = id
    confirm(true)
  }

  // モーダルで「はい」を選択して呼ばれる
  // removeCommentIdをセット、削除対象のCommentItemのuseEffect内でフェードアウト発動
  // 確認画面を閉じる
  const remove = () => {
    $cinra.$api
      .delete(`/article/${props.articleId}/comments/${commentId.current}`)
      .then(() => {
        confirm(false)

        const index = [...data].findIndex((comment) => {
          return comment.id === commentId.current
        })
        setData((prev: any) => {
          prev.splice(index, 1)
          return [...prev]
        })
        setCommented(false)
        commentId.current = null
        setCount(count - 1)
      })
      .catch((error) => {
        alert('エラーが発生しました。時間をおいてもう一度お試しください。')
        console.error(error.response.data.message)
        confirm(false)
      })
  }

  // 削除せずモーダルを閉じる
  const closeModal = () => {
    confirm(false)
    commentId.current = null
  }

  const post = (comment: string): void => {
    document.documentElement.dataset.wait = 'true'
    process(true)

    $cinra.$api
      .post(`/article/${props.articleId}/comments/`, {
        body: comment,
      })
      .then(({ data }) => {
        setData((prev: any) => {
          const { id, user_id, body } = data

          prev.push({
            id,
            created_at: 'Just Now',
            body,
            user: {
              id: user_id,
              name: props.userName,
              icon: props.userIcon,
            },
          })
          return [...prev]
        })
        setCommented(true)
        setCount(count + 1)
      })
      .catch((error) => {
        console.error(error.response.data.message)
        window.location.href = '/mypage/login'
      })
      .finally(() => {
        document.documentElement.dataset.wait = ''
        process(false)
      })
  }

  const fetch = ({ page }) => {
    return $cinra.$api.get(`/article/${props.articleId}/comments/`, {
      params: { page },
    })
  }

  const handleMore = () => {
    document.documentElement.dataset.wait = 'true'
    process(true)

    fetch({ page })
      .then(({ data }) => {
        setPage(page + 1)
        setData((prev) => {
          return [...prev, ...data.data]
        })
      })
      .catch((error) => {
        console.error(error.response.data.message)
      })
      .finally(() => {
        document.documentElement.dataset.wait = ''
        process(false)
      })
  }

  useEffect(() => {
    fetch({ page })
      .then(({ data }) => {
        setCount(data.total)
        setPage(page + 1)
        setData(data.data)
        total.current = data.total
      })
      .catch((error) => {
        console.error(error.response.data.message)
      })
  }, [])

  // 最後まで取得したら「もっとみる」非表示
  useEffect(() => {
    if (data.length >= total.current) {
      done(true)
    }
  }, [data])

  return (
    <>
      <dl className="p-comment">
        <dt>
          <span>コメント</span>
          <em className="p-comment__count">{count}</em>
        </dt>
        <dd>
          <TransitionGroup className="p-comment__items">
            {data.map((comment) => {
              return (
                <CSSTransition
                  classNames="item"
                  timeout={500}
                  key={comment.id}
                >
                  {comment.banned ? (
                    <CommentBannedItem />
                  ) : (
                    <CommentItem
                      data={comment}
                      userId={props.userId}
                      handleRemove={handleRemove}
                    />
                  )}
                </CSSTransition>
              )
            })}
          </TransitionGroup>

          {!isDone && (
            <div className="p-comment__overlay">
              <button
                type="button"
                className="p-comment__more"
                disabled={processing}
                onClick={handleMore}
              >
                もっとみる
              </button>
            </div>
          )}

          {/* コメント投稿 */}
          {props.userId && (
            <CommentForm
              icon={props.userIcon}
              post={post}
              commented={commented}
              processing={processing}
            />
          )}

          {/* コメント投稿 */}
          {!props.userId && (
            <div className="p-commentLogin">
              <p className="p-commentLogin__text">
                会員登録するとコメントを投稿できます
              </p>
              <a
                href="/mypage/login"
                className="p-commentLogin__button c-button -outline -center"
              >
                <span className="c-button__inner">
                  <i className="c-button__icon iconfont-arrow-right"></i>
                  会員登録 / ログインページへ
                </span>
              </a>
            </div>
          )}
        </dd>
      </dl>

      {/* 削除モーダル */}
      <div className="p-commentModal" aria-hidden={!confirmation}>
        <div className="p-commentModal__overlay" onClick={closeModal}></div>
        <div className="p-commentModal__content">
          <div className="p-commentModal__body">
            <p className="p-commentModal__confirmation">
              コメントを削除しますか？
            </p>
            <ul className="p-commentModal__buttons">
              <li>
                <button
                  type="button"
                  className="p-commentModal__button"
                  onClick={remove}
                >
                  はい
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="p-commentModal__button"
                  onClick={closeModal}
                >
                  いいえ
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Comment

import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import Spinner from './Spinner'
import API from '@cinra/rest-api-client'

dayjs.locale('en')

const NewsItem = ({ data }) => {
  const getDate = (openDate) => {
    return dayjs(openDate).format('YYYY.MM.DD ddd')
  }

  const date = useRef(getDate(data.open_date))

  return (
    <li>
      <div className="p-newsCard">
        <a href={`/news/${data.slug}`} className="p-newsCard__image">
          <picture className="c-thumbnail">
            <img src={`https://former-cdn.cinra.net/uploads/img/${data.img_path}`} alt="" loading="lazy" decoding="async" className="" />
          </picture>
        </a>
        <div className="p-newsCard__text">
          <p className="p-newsCard__title">
            <a href={`/news/${data.slug}`}>{data.title}</a>
          </p>
          <div className="p-newsCard__meta">
            <em className="p-newsCard__date">{date.current}</em>
            {/* <a href="#" className="p-newsCard__category">ART&amp;DESIGN</a>     */}
          </div>
        </div>
      </div>
    </li>
  )
}

const $cinra = new API.CinraService()

const NewsList = () => {

  // リスト
  const [items, setItems] = useState([])

  // 全件取得、スピナーを非表示
  const [isDone, done] = useState(false)

  // API通信中か
  const fetching = useRef(false)

  // 記事の現在ページ
  const currentPage = useRef(2)

  // 記事の総件数
  const total = useRef(-1)

  // 表示件数
  const perPage = useRef(20)

  const fetch = () => {
    if (fetching.current) {
      return
    }

    fetching.current = true

    $cinra.$api
      .get('/news/getposts', {
        params: {
          page: currentPage.current
        }
      })
      .then(({ data }) => {
        setItems((prev) => {
          return [...prev, ...data.data]
        })
        fetching.current = false
        currentPage.current += 1
        total.current = data.total
        perPage.current = data.per_page
      })
      .catch((error) => {
        console.error(error.response.data.message)
        done(true)
      })
  }

  // 最後まで取得したらスピナー非表示
  useEffect(() => {
    if (total.current > 0 && (items.length + perPage.current) >= total.current) {
      done(true)
    }
  }, [items])

  return (
    <>
      <ul className="p-newsList" data-added="true">
        {items.map((item) => {
          return <NewsItem data={item} key={item.id} />
        })}
      </ul>
      {!isDone && <Spinner handleFetch={fetch} />}
    </>
  )
}

export default NewsList

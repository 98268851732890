import React, { useEffect, useRef, useState, useCallback } from 'react'

const Spinner = ({ handleFetch }) => {
  const element = useRef(null)

  const [countAutoLoad, setCountAutoLoad] = useState(0)
  const [isFetching, setIsFetchng] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)

  const handlerIntersect = useCallback((entries) => {
    setIsIntersecting(entries[0].isIntersecting)
  }, [])

  const handlerButtonClick = () => {
    setIsFetchng(true)
    handleFetch().then(() => {
      setIsFetchng(false)
      setCountAutoLoad(() => 0)
    })
  }

  const observer = new IntersectionObserver(handlerIntersect, {
    rootMargin: '0% 0% -80px',
  })

  useEffect(() => {
    // console.log('effect')
    observer.observe(element.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isIntersecting && !isFetching) {
      // console.log(countAutoLoad, 'intersect')
      if (countAutoLoad < 2) {
        setIsFetchng(true)
        // console.log('handleFetch', handleFetch)
        handleFetch().then(() => {
          setIsFetchng(false)
          setCountAutoLoad((prev) => prev + 1)
        })
      }
    }
  }, [isIntersecting])

  return (
    <div ref={element} className="p-spinner">
      {isFetching && <div className="p-spinner__main c-spinner"></div>}
      {countAutoLoad === 2 && !isFetching && (
        <button
          type="button"
          className="p-spinner__button c-button -outline -center"
          onClick={handlerButtonClick}
        >
          <span className="c-button__inner">さらに読み込む</span>
        </button>
      )}
    </div>
  )
}

export default Spinner

import React, { memo, useEffect, useCallback, useRef, useState } from 'react'
import SwiperCore, { Navigation, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { createUID } from '../../utils'
// import project from '../../project'

SwiperCore.use([Navigation, EffectFade])

const Gallery = (props) => {
  const [ready, setReady] = useState(false)
  const [slides, setSlides] = useState([])
  const [galleryIndex, setCurrentIndex] = useState(1)
  const [shortcut, setShortcut] = useState(null)
  const [isHidden, setHidden] = useState(true)
  const refRoot = useRef(null)
  const refCarousel = useRef(null)
  const swiperConfig = {
    slidesPerView: 1,
    speed: 600,
    autoHeight: false,
    effect: 'fade',
    allowTouchMove: false, // NOTE: default: true の場合 react-zoom-pan-pinch が効かなくなるため指定
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        autoHeight: true,
      },
    },
  }

  const handleTriggerClick = (e) => {
    const newIndex = Number(e.currentTarget.dataset.galleryIndex)
    refCarousel?.current?.swiper.slideTo(newIndex)

    setCurrentIndex(newIndex + 1)
    open()
  }

  const handleSlideChange = useCallback(
    (swiper) => {
      const newIndex =
        swiper?.realIndex + 1 || refCarousel?.current?.swiper.realIndex + 1

      setCurrentIndex(newIndex)
    },
    [galleryIndex]
  )

  const open = () => {
    setHidden(false)
    refCarousel?.current?.swiper.update()
    document.documentElement.dataset.modal = true
  }

  const close = () => {
    setHidden(true)
    document.documentElement.dataset.modal = ''
  }

  /**
   * @doc 記事詳細内の画像を拾い、スライドとしてのデータを生成
   */
  useEffect(() => {
    const detectTargets = [...document.querySelectorAll('.js-gallery')].map(
      (el, i) => {
        const target = el.querySelector('.js-galleryImage')
        const captionElement = el.querySelector('.js-galleryCaption')
        const targetSrc =
          target.dataset?.src || target.getAttribute('src') || ''
        const type = target?.tagName?.toLowerCase() || 'img'
        const alt = target?.getAttribute('alt') || ''

        el.dataset.galleryIndex = i
        target.style.cursor = 'pointer'
        el.addEventListener('click', handleTriggerClick)

        return {
          id: createUID(i),
          src: targetSrc,
          alt,
          type,
          caption: captionElement ? captionElement.textContent : '',
        }
      }
    )

    setSlides(detectTargets)

    // TODO ギャラリーが知らなくていい処理なので、最終的にmoduleに移し、こちらは外部open用のAPIを用意できるとベスト
    // eslint-disable-next-line
    if (props.galleryShortcut) {
      // eslint-disable-next-line
      setShortcut(props.galleryShortcut)
    }

    setReady(true)

    return () => {}
  }, [])

  useEffect(() => {
    if (!shortcut && ready) {
      return
    }

    const newIndex = Number(shortcut)

    if (newIndex && newIndex > 0 && newIndex <= slides.length) {
      refCarousel?.current?.swiper.slideTo(newIndex)
      setCurrentIndex(newIndex)
      open()
    }
  }, [slides, shortcut])

  useEffect(() => {}, [galleryIndex])

  return (
    <div
      ref={refRoot}
      className="l-gallery js-galleryRoot"
      aria-hidden={isHidden.toString()}
    >
      {/* オーバーレイ */}
      <div
        className="l-gallery__overlay js-galleryClose"
        onClick={close}
      ></div>
      <div className="l-gallery__container">
        <div className="l-gallery__header">
          {/* SP前後ボタン */}
          <ul className="l-gallery__buttons">
            <li>
              <div className="swiper-button-prev l-gallery__arrow -prev iconfont-arrow-left"></div>
            </li>
            <li>
              <div className="swiper-button-next l-gallery__arrow -next iconfont-arrow-right"></div>
            </li>
          </ul>

          {/* 数 */}
          <div className="l-gallery__count">
            <span className="js-galleryIndex">
              {typeof galleryIndex === 'number' ? galleryIndex : 1}
            </span>
            /<span>{slides.length}</span>
          </div>

          {/* SP閉じるボタン */}
          <button
            type="button"
            className="l-gallery__close u-sp-block iconfont-close js-galleryClose"
            aria-label="ギャラリーを閉じる"
            onClick={close}
          ></button>
        </div>
        <div className="l-gallery__content">
          {/* PC閉じるボタン */}
          <button
            type="button"
            className="l-gallery__close u-sp-hidden iconfont-close js-galleryClose"
            aria-label="ギャラリーを閉じる"
            onClick={close}
          ></button>

          {/* スライド本体 */}
          <Swiper
            ref={refCarousel}
            className="l-gallery__slider js-gallerySlider"
            {...swiperConfig}
            onSlideChange={handleSlideChange}
          >
            {slides.map((slide, i) => {
              return (
                <SwiperSlide
                  key={slide.id}
                  style={
                    i === refCarousel?.current?.swiper.activeIndex
                      ? { zIndex: 100 }
                      : {}
                  }
                >
                  <figure className="l-gallery__item">
                    <div className="l-gallery__image">
                      {!isHidden && (
                        <>
                          {slide.type === 'iframe' && (
                            <div className="l-gallery__iframe">
                              <iframe
                                src={slide.src}
                                frameBorder="0"
                                allowFullScreen
                                playsInline
                                muted
                              ></iframe>
                            </div>
                          )}
                          {slide.type === 'img' && (
                            <TransformWrapper
                              key={slide.id}
                              centerOnInit={true}
                              minScale={0.5}
                              maxScale={1.5}
                              initialScale={0.8}
                              wheel={{ step: 0.1 }}
                            >
                              <TransformComponent>
                                <img src={slide.src} alt={slide.alt} />
                              </TransformComponent>
                            </TransformWrapper>
                          )}
                        </>
                      )}
                    </div>
                    {slide.caption && <figcaption>{slide.caption}</figcaption>}
                  </figure>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {/* .swiper-container */}

          {/* PC前後ボタン */}
          <div className="swiper-button-prev l-gallery__arrow -prev u-sp-hidden iconfont-arrow-left"></div>
          <div className="swiper-button-next l-gallery__arrow -next u-sp-hidden iconfont-arrow-right"></div>
        </div>

        {/* SP前後ボタン */}
        <ul className="l-gallery__buttons">
          <li>
            <div className="swiper-button-prev l-gallery__arrow -prev iconfont-arrow-left"></div>
          </li>
          <li>
            <div className="swiper-button-next l-gallery__arrow -next iconfont-arrow-right"></div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default memo(Gallery)

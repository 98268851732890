import React, { useState, useEffect, useRef } from 'react'
import Spinner from './Spinner'
import API from '@cinra/rest-api-client'

type ArticleListParams = {
  slug?: string
  key?: string
}
type ArticleRequest = {
  endpoint: string
  params?: ArticleListParams
}

const ArticleItem = ({ data }) => {
  const isSubmedia = !!data.submedia

  const url = isSubmedia ? data.url : `/${data.type}/${data.slug}`
  const image = isSubmedia
    ? data.eyecatch
    : `https://former-cdn.cinra.net/uploads/img/${data.img_path}`
  const title = data.title
  const contributor = isSubmedia
    ? data.author || null
    : data.contributors?.[0] || null
  const client = data.client || null
  const heading = data.heading || null
  const isPr = data.isPr || false

  return (
    <li>
      <div className="p-articleCard -desktop-horizontal -desktop-horizontal-medium">
        <a href={url} className="p-articleCard__image">
          <picture className="c-thumbnail">
            <img
              src={image}
              alt=""
              loading="lazy"
              decoding="async"
              className=""
            />
          </picture>
        </a>
        <div className="p-articleCard__text">
          <ul className="p-articleCard__categoryList">
            {data.tags && (
              <li>
                <b className="p-articleCard__group">{data.tags}</b>
              </li>
            )}
            {data.series && (
              <li>
                <b className="p-articleCard__series">{data.series}</b>
              </li>
            )}
          </ul>

          <p className="p-articleCard__title">
            <a href={url}>{title}</a>
          </p>

          {heading && (
            <p className="p-articleCard__lead">
              <a href={url}>{heading}</a>
            </p>
          )}

          {isPr && client && (
            <em className="p-articleCard__sponsor">Sponsored by {client}</em>
          )}

          <div className="p-articleCard__meta">
            {/* 著者(submedia) */}
            {contributor && isSubmedia && (
              <div className="p-articleCard__author c-author">
                <span className="c-author__name">{contributor}</span>
              </div>
            )}
            {/*  著者(新メディア) */}
            {contributor && !isSubmedia && (
              <div className="p-articleCard__author c-author">
                <a
                  href={`/contributor/${contributor.id}`}
                  className="c-author__content"
                >
                  <img
                    src={contributor.img_path_url || ''}
                    alt=""
                    loading="lazy"
                    decoding="async"
                    className="c-author__image"
                  />
                  <span className="c-author__name">by {contributor.name}</span>
                </a>
              </div>
            )}
            {/* リアクション数 */}
            {!isSubmedia && (
              <em className="p-articleCard__reaction iconfont-heart">
                {data.reactionCount}
              </em>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}

const $cinra = new API.CinraService()

const ArticleList = ({ slug, keyword, endpoint }) => {
  const ep =
    {
      tag: '/article/gettagposts',
      search: '/search',
    }[endpoint] || '/article/getposts'

  const api: ArticleRequest = {
    endpoint: ep,
    params: {},
  }

  if (slug) {
    api.params.slug = slug
  }

  if (keyword) {
    api.params.key = keyword
  }

  // リスト
  const [items, setItems] = useState([])

  // 全件取得、スピナーを非表示
  const [isDone, done] = useState(false)

  // API通信中か
  const fetching = useRef(false)

  // 記事の現在ページ
  const currentPage = useRef(2)

  // 記事の総件数
  const total = useRef(-1)

  // 表示件数
  const perPage = useRef(20)

  const fetch = async () => {
    if (fetching.current) {
      return
    }

    fetching.current = true

    return await $cinra.$api
      .get(api.endpoint, {
        params: {
          ...api.params,
          page: currentPage.current,
        },
      })
      .then(({ data }) => {
        setItems((prev) => {
          return [...prev, ...data.data]
        })
        fetching.current = false
        currentPage.current += 1
        total.current = data.total
        perPage.current = data.per_page
      })
      .catch((error) => {
        console.error(error.response.data.message)
        done(true)
      })
  }

  // 最後まで取得したらスピナー非表示
  useEffect(() => {
    if (total.current > 0 && items.length + perPage.current >= total.current) {
      done(true)
    }
  }, [items])

  return (
    <ul className="p-articleList">
      {items.map((item) => {
        return <ArticleItem data={item} key={item.id} />
      })}
      {!isDone && <Spinner handleFetch={fetch} />}
    </ul>
  )
}

export default ArticleList

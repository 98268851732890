import PubSub from 'vanilla-pubsub'
import React, { useState, useRef } from 'react'
import Croppie from 'croppie'
import project from '../../project'
import { useDidMount } from '../utils'

interface ICroppingProps {
  url: string
}

const Cropping: React.FC<ICroppingProps> = (props) => {
  const [ready, setReady] = useState(false)
  const [visible, setVisible] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [croppie, setCroppie] = useState<Croppie | null>(false)

  const refModal = useRef<HTMLDivElement>(null)
  const refCroppie = useRef<HTMLDivElement>(null)

  let croppieInstance = null

  const bindHandler = async (data) => {
    await croppieInstance.bind(data)
    setVisible(true)
  }

  const saveHandler = async () => {
    setProcessing(true)
    const data = await croppie.result('base64')

    if (!data) {
      return
    }

    PubSub.publish('Cropping.saved', data)
  }

  const cancelHandler = () => {
    PubSub.publish('Cropping.canceled')
    setVisible(false)
  }

  const doneHandler = () => {
    setProcessing(false)
    setVisible(false)
  }

  const bind = () => {
    PubSub.subscribe('Cropping.bind', bindHandler)
    PubSub.subscribe('Cropping.done', doneHandler)
  }

  useDidMount(() => {
    croppieInstance = new Croppie(refCroppie.current, {
      enableExif: true,
      enableOrientation: true,
      showZoomer: true,
      viewport: {
        height: 120,
        width: 120,
        type: 'circle',
      },
      boundary: {
        height: project.state.isMobile
          ? (window.innerWidth - 40) * 0.5625
          : 280,
        width: project.state.isMobile ? window.innerWidth - 40 : 400,
      },
    })

    bind()
    setCroppie(croppieInstance)
    setReady(true)
  })

  return (
    <div
      aria-hidden={visible ? 'false' : 'true'}
      ref={refModal}
      className={`croppie-root${processing ? ' is-processing' : ''}`}
    >
      <div ref={refCroppie}></div>
      <div className="croppie-action">
        <button type="button" className="cancel" onClick={cancelHandler}>
          キャンセル
        </button>
        <button type="button" className="save" onClick={saveHandler}>
          保存する
        </button>
      </div>
    </div>
  )
}

export default Cropping

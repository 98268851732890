import React from 'react'

const GreenCompany = ({ companies }) => {
  return (
    <ul className="p-greenCompany__list">
      {companies.map((company) => {
        const color = company.green_company[0].acf.color
        return (
          <li key={company.id}>
            <a
              href={`https://job.cinra.net${company.link}`}
              className="p-greenCompanyItem"
            >
              <img
                src={company.acf.main_images[0].url}
                alt=""
                className="p-greenCompanyItem__image"
                decoding="async"
                loading="lazy"
              />
              <em
                className="p-greenCompanyItem__label"
                style={{ background: color }}
              >
                <i
                  className="p-greenCompanyItem__icon iconfont-check"
                  style={{ color }}
                ></i>
                {company.green_company[0].name}
              </em>
              <b className="p-greenCompanyItem__name">
                {company.title.rendered}
              </b>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default GreenCompany

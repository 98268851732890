import React, { useState, useEffect, useRef } from 'react'
import Spinner from './Spinner'
import API from '@cinra/rest-api-client'

const ProductionItem = ({ data }) => {
  return (
    <div className="p-productionList__item">
      <a
        href={`/article/${data.slug}`}
        className="p-articleCard -desktop-horizontal -desktop-horizontal-medium -desktop-image-small"
      >
        <div className="p-articleCard__image">
          <picture className="c-thumbnail">
            <img
              src={`https://former-cdn.cinra.net/uploads/img/${data.img_path}`}
              alt=""
              loading="lazy"
              decoding="async"
              className=""
            />
          </picture>
        </div>
        <div className="p-articleCard__text">
          {/* <b className="p-articleCard__group">{data.summary}</b> */}
          <p className="p-articleCard__title">{data.title}</p>
        </div>
      </a>
    </div>
  )
}

const $cinra = new API.CinraService()

const ProductionList = ({ contributorId }) => {
  const endpoint = `contributor/getarticlepost/${contributorId}`

  // リスト
  const [items, setItems] = useState([])

  // API通信中か
  const fetching = useRef(false)

  // 全県取得、スピナーを非表示
  const [isDone, done] = useState(false)

  // 記事の現在ページ
  const currentPage = useRef(2)

  // 記事の総件数
  const total = useRef(-1)

  // 表示件数
  const perPage = useRef(20)

  const fetch = async () => {
    if (fetching.current) {
      return
    }

    fetching.current = true

    return await $cinra.$api
      .get(endpoint, {
        params: {
          page: currentPage.current,
        },
      })
      .then(({ data }) => {
        // console.log(data)
        setItems((prev) => {
          return [...prev, ...data.data]
        })
        fetching.current = false
        currentPage.current += 1
        total.current = data.total
        perPage.current = data.per_page
      })
      .catch((error) => {
        console.error(error.response.data.message)
        done(true)
      })
  }

  // 最後まで取得したらスピナー非表示
  useEffect(() => {
    if (total.current > 0 && items.length + perPage.current >= total.current) {
      done(true)
    }
  }, [items])

  return (
    <>
      {items.map((item) => {
        return <ProductionItem data={item} key={item.id} />
      })}
      {!isDone && <Spinner handleFetch={fetch} />}
    </>
  )
}

export default ProductionList

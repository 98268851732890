import ComponentRenderer from '../react'
import API from '@cinra/rest-api-client'

export default class {
  constructor(selector) {
    this.el = document.querySelector(selector)
    this.instance = null
    this.$cinra = new API.CinraService()

    if (this.el) {
      // console.log('setup')
      this.setup()
    }
  }

  async setup() {
    const { data } = await this.$cinra.$api.get('/job').catch(() => {
      return []
    })

    ComponentRenderer.render(
      'GreenCompany', // Registered component name
      this.el, // Render target
      { companies: data } // Pass Props
    )
  }
}

import React from 'react'
import { render } from 'react-dom'

// components
import Cropping from './components/Cropping.tsx'
import Comment from './components/Comment.tsx'
import DatePicker from './components/DatePicker.tsx'
import ProductionList from './components/ProductionList.tsx'
import Series from './components/Series.tsx'
import ArticleList from './components/ArticleList.tsx'
import { default as Gallery } from './components/Gallery.jsx'
import NewsList from './components/NewsList.tsx'
import GreenCompany from './components/GreenCompany.tsx'
import Tmpl from './components/Tmpl.tsx'

class ComponentRenderer {
  constructor() {
    this.components = {
      Cropping,
      Comment,
      DatePicker,
      ProductionList,
      Series,
      ArticleList,
      Gallery,
      NewsList,
      GreenCompany,
      Tmpl,
    }
  }

  render(name, targetElement, props = {}) {
    return render(
      React.createElement(this.components[name], props, null),
      targetElement
    )
  }
}

export default new ComponentRenderer()

import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

// for react-dates
import weekday from 'dayjs/plugin/weekday'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'

const weekDayShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

dayjs.locale(ja)

dayjs.extend(weekday)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(localeData)

export default dayjs
export { dayjs, weekDayShort }

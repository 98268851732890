import PubSub from 'vanilla-pubsub'
import { wait } from '../utils'

const defaultStyle = {
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  'z-index': '100',
  transform: 'translate3d(0,0,0)',
  opacity: 0,
  'background-color': 'rgba(255,255,255,0.9)',
}

export { defaultStyle }

export default class {
  constructor(config) {
    this.state = {
      el: null,
      duration: '0s',
      isVisible: false,
      isCreated: false,
    }

    this.config = {
      dark: false,
      targetSelector: 'body',
      externalElements: null,
      overlayStyle: config.dark
        ? { ...defaultStyle, 'background-color': 'rgba(0,0,0,0.2)' }
        : defaultStyle,
      ...config,
    }

    this.bind()
  }

  get isVisible() {
    return this.state.isVisible
  }

  get isCreated() {
    return this.state.isCreated
  }

  bind() {
    this.createOverlay = this.createOverlay.bind(this)
    this.visible = this.visible.bind(this)
    this.hide = this.hide.bind(this)
    this.destroy = this.destroy.bind(this)
  }

  createOverlay() {
    if (this.state.isCreated) {
      console.error('Overlay: Already exists.')
      return
    }

    // Create overlay element.
    this.state.el = document.createElement('div')

    // Attachment overlay style. (configurable)
    this.state.el.style.cssText = Object.entries(this.config.overlayStyle)
      .map((x) => `${x[0]}:${x[1]};`)
      .join('\n')

    // Get CSS transition duration.
    this.state.duration = this.getStyle(this.state.el).transitionDuration

    // Set click event of overlay.
    this.state.el.addEventListener('click', this.onClickOverlay.bind(this))

    // Get where to add elements.
    const target = document.querySelector(this.config.targetSelector)

    target?.appendChild(this.state.el)
    this.state.isCreated = true
  }

  async visible() {
    if (!this.state.isCreated) {
      console.error('Overlay: Explicitly create an overlay before visible.')
      return
    }

    if (this.config.externalElements.length) {
      ;[...this.config.externalElements].forEach((el) => {
        el.style.zIndex = '0'
      })
    }

    this.state.el.style.display = 'block'
    await wait(10)
    this.state.el.style.opacity = 1

    this.state.isVisible = true
  }

  async hide() {
    if (!this.state.isCreated) {
      console.error('Overlay: Explicitly create an overlay before hide.')
      return
    }

    this.state.el.style.opacity = 0
    await wait(parseFloat(this.state.duration))
    this.state.el.style.display = 'none'
    await wait(10)

    if (this.config.externalElements.length) {
      ;[...this.config.externalElements].forEach((el) => {
        el.style.zIndex = ''
      })
    }

    this.state.isVisible = false
  }

  getStyle() {
    if (!this.state.el) {
      console.error('Overlay: Create an overlay before getStyle.')
      return
    }

    return window.getComputedStyle(this.state.el)
  }

  destroy() {
    this.state.el.remove()
    this.state.isCreated = false
    this.state.isVisible = false
  }

  onClickOverlay(e) {
    PubSub.publish('Overlay.click', e)
  }
}

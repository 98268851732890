import React, { useState, useRef } from 'react'
import { useDidMount } from '../utils'

interface ITmplItem {
  text: string
}

interface IProps extends ITmplItem {
  root: HTMLElement
}

const Tmpl: React.FC<IProps> = (props) => {
  const [ready, setReady] = useState(false)
  const root = useRef<HTMLElement>(props.root)

  useDidMount(() => {
    setReady(true)
  })
  // useEffect(() => {}, [current.title])

  return (
    <div className={`p-floatingNav${ready ? ' is-ready' : ''}`}>
      {props.text}
    </div>
  )
}

export default Tmpl

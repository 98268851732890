import {
  throttle as _throttle,
  debounce as _debounce,
} from 'throttle-debounce'
import platform from 'platform'

/* eslint-disable no-unused-vars */
// import detectTouchEvents from 'detect-touch-events'
import { BREAK_POINT } from './helpers'
const _userAgent = navigator.userAgent.toLowerCase()

export function decodeHtmlSpecialCahrs(str) {
  const map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
  }
  return str.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, (m) => map[m])
}

/**
 * getDataOption
 * @param {HTMLElement} target
 * @param {string} name
 */
export function getDataOption(target, name) {
  const result = {}

  try {
    Object.assign(
      result,
      target.dataset[name] ? JSON.parse(target.dataset[name]) : {}
    )
  } catch (err) {
    console.error(err)
  }
  return result
}

/**
 * wait
 */
export function wait(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

/**
 * throttle
 */
export function throttle(func, wait = 100) {
  return _throttle(wait, func)
}

/**
 * debounce
 */
export function debounce(func, wait = 100) {
  return _debounce(wait, func)
}

/**
 * isMobile
 */
export function isMobile() {
  // return window.innerWidth <= BREAK_POINT
  return window.matchMedia(`(max-width: ${BREAK_POINT}px)`).matches
}

// /**
//  * getTapEvent
//  */
// export function getTapEvent() {
//   if (!detectTouchEvents.hasSupport) {
//     return 'click'
//   }
//   return document.documentElement.clientWidth > BREAK_POINT
//     ? 'click'
//     : 'touchstart'
// }

/**
 * getBrowser
 */
export function getBrowser() {
  if (
    _userAgent.indexOf('msie') != -1 ||
    _userAgent.indexOf('trident') != -1
  ) {
    return 'ie'
  } else if (_userAgent.indexOf('edge') != -1) {
    return 'edge'
  } else if (_userAgent.indexOf('chrome') != -1) {
    return 'chrome'
  } else if (_userAgent.indexOf('safari') != -1) {
    return 'safari'
  } else if (_userAgent.indexOf('firefox') != -1) {
    return 'firefox'
  } else if (_userAgent.indexOf('opera') != -1) {
    return 'opera'
  } else {
    return 'unknown'
  }
}

/**
 * getOperatingSystem
 */
export function getOS() {
  const os = {
    family: '',
    version: {
      major: '',
      minor: '',
      patch: '',
    },
  }

  try {
    const { family, version } = platform.os
    const [major, minor, patch] = version.split('.')
    os.family = (family || '').replace(/\s+/g, '').toLowerCase()
    os.version = {
      major,
      minor,
      patch,
    }
  } catch (err) {
    console.error(err)
  }

  return os
}

/**
 * createUID
 */
export function createUID(v = '') {
  let result = ''
  const r = Math.floor(Math.random() * 8)

  ;[...Math.random().toString(36).slice(-8)].forEach((c) => {
    result += Math.random() >= 0.5 ? c.toUpperCase() : c
  })

  return `${result.slice(0, r)}${v}${result.slice(r)}`
}

/**
 * setCookie
 */
export function setCookie(expire_hour, name, value = 1) {
  const expire = new Date()
  expire.setTime(expire.getTime() + 1000 * 3600 * expire_hour)
  document.cookie = `${name}=${value}; expires=${expire.toUTCString()}`
}

/**
 * getCookie
 */
export function getCookie(name) {
  let result = null

  const cookieName = name + '='
  const allcookies = document.cookie

  const position = allcookies.indexOf(cookieName)
  if (position != -1) {
    const startIndex = position + cookieName.length

    let endIndex = allcookies.indexOf(';', startIndex)
    if (endIndex == -1) {
      endIndex = allcookies.length
    }

    result = decodeURIComponent(allcookies.substring(startIndex, endIndex))
  }

  return result
}

/**
 * queryToObject
 */
export function queryToObject(querystring) {
  const pairs = querystring.replace(/^\?/, '').split('&')
  const searchParams = pairs.map((x) => x.split('=')).filter((x) => x[0])

  return [...searchParams].reduce((acc, cur) => {
    const key = cur[0].replace('[]', '')

    return {
      ...acc,
      [key]:
        (/\[\]$/.test(cur[0])
          ? !acc[key]
            ? [cur[1]]
            : acc[key].concat(cur[1])
          : cur[1]) || '',
    }
  }, {})
}

/**
 * formatBytes
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * arrayBufferToBase64
 */
export function arrayBufferToBase64(arrayBuffer) {
  return window.btoa(
    new Uint8Array(arrayBuffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
}

/**
 * getImageFileType
 */
export function getImageFileType(arrayBuffer) {
  const ba = new Uint8Array(arrayBuffer)
  let headerStr = ''
  let headerHex = ''
  let fileType = 'unknown'

  for (let i = 0; i < 10; i++) {
    // 始めの10個分を読む
    headerHex += ba[i].toString(16) // 16進文字列で読む
    headerStr += String.fromCharCode(ba[i]) // 文字列で読む
  }
  if (headerHex.indexOf('ffd8') != -1) {
    // JPGはヘッダーに「ffd8」を含む
    fileType = 'JPG'
  } else if (headerStr.indexOf('PNG') != -1) {
    // PNGはヘッダーに「PNG」を含む
    fileType = 'PNG'
  } else if (headerStr.indexOf('GIF') != -1) {
    // GIFはヘッダーに「GIF」を含む
    fileType = 'GIF'
  } else if (headerStr.indexOf('BM') != -1) {
    // BMPはヘッダーに「BM」を含む
    fileType = 'BMP'
  }
  return fileType
}

import React, { useState, useEffect, useRef } from 'react'
import Spinner from './Spinner'
import API from '@cinra/rest-api-client'

const SeriesItem = ({ item }) => {
  const contributor = item.contributors?.[0] || null

  return (
    <a href={`/article/${item.slug}`} className="p-articleCard ">
      {item.img_path && (
        <div className="p-articleCard__image">
          <picture className="c-thumbnail">
            <img
              src={`https://former-cdn.cinra.net/uploads/img/${item.img_path}`}
              alt=""
              loading="lazy"
              decoding="async"
            />
          </picture>
        </div>
      )}
      <div className="p-articleCard__text">
        <p className="p-articleCard__title">{item.title}</p>
        <div className="p-articleCard__meta">
          {contributor && (
            <div className="p-articleCard__author c-author">
              {contributor.img_path && (
                <img
                  src={contributor.img_path}
                  alt=""
                  loading="lazy"
                  decoding="async"
                  className="c-author__image"
                />
              )}
              <span className="c-author__name">by {contributor.name}</span>
            </div>
          )}
          <em className="p-articleCard__reaction iconfont-heart">
            {item.reactionCount}
          </em>
        </div>
      </div>
    </a>
  )
}

const SeriesSection = ({ data }) => {
  return (
    <section className="p-series" data-added="true">
      <div className="p-series__header">
        <h3 className="p-series__heading iconfont-arrow-series">
          {data.title}
        </h3>
        <a
          href={`/series/${data.slug}`}
          className="p-series__link iconfont-chevron-right"
        >
          すべて見る
        </a>
      </div>
      <div className="p-items -mobile-horizontal">
        <ul className="p-items__list">
          {data.articles.map((article) => {
            return (
              <li key={article.id}>
                <SeriesItem item={article} />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

const $cinra = new API.CinraService()

const Series = (props) => {
  const [items, setItems] = useState([])

  // 全県取得、スピナーを非表示
  const [isDone, done] = useState(false)

  // API通信中か
  const fetching = useRef(false)

  // 記事の現在ページ
  const currentPage = useRef(2)

  // 記事の総件数
  const total = useRef(-1)

  // 表示件数
  const perPage = useRef(20)

  const fetch = () => {
    if (fetching.current) {
      return
    }

    fetching.current = true

    $cinra.$api
      .get('/articlegroup/getposts', {
        params: {
          page: currentPage.current,
        },
      })
      .then(({ data }) => {
        setItems((prev) => {
          return [...prev, ...data.data]
        })
        fetching.current = false
        currentPage.current += 1
        total.current = data.total
        perPage.current = data.per_page
      })
      .catch(() => {
        console.error('An error has occured!')
        done(true)
      })
  }

  // 最後まで取得したらスピナー非表示
  useEffect(() => {
    if (total.current > 0 && items.length + perPage.current >= total.current) {
      done(true)
    }
  }, [items])

  return (
    <>
      {items.map((item) => {
        return <SeriesSection data={item} key={item.id} />
      })}

      {!isDone && <Spinner handleFetch={fetch} />}
    </>
  )
}

export default Series
